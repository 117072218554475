/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import OptionsDropDown from '../OptionsDropdown';
import caretIcon from '../../assets/icons/downCaret.svg';
import { formatDate } from '../../utils/timeFormatter';
import Back from '../Back';
import { connect } from 'react-redux';
import { PageLoader } from '../Loader';
import InvoiceDetailModal from '../InvoiceModal';
import formatMoney from '../../utils/formatMoney';
import capitalize from '../../utils/capitalize';
import {
  addPaymentToAnInvoiceAction,
  getSingleInvoiceAction,
  updateInvoiceDetailsAction,
} from '../../store/modules/invoice';
import { useHistory } from 'react-router-dom';
import { download } from '../../utils/filleUtils';
import PreviewModal from '../PreviewModal';
import {
  generateCreditNoteAction,
  generateDebitNoteAction,
  sendDebitNoteToClientAction,
  sendInvoiceAction,
  generateReceiptNoteAction,
  sendReceiptNoteToClientAction,
} from '../../store/modules/quotes';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import { sendCreditNoteAction } from '../../store/modules/policy';
import useErrorHandler from '../hooks/useErrorHandler';
import axios from '../../utils/http';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { InvoiceTransactionTypes } from '../../constants';
import { getAllAccounts } from '../../store/modules/transaction';

const defaultPreviewState = {
  url: '',
  type: '',
  open: false,
  filename: '',
};

const useStyles = makeStyles({
  button: {
    fontSize: '12px !important',
    background: '#03658C !important',
    color: 'white !important',
    fontWeight: '600 !important',
    '&:focus': {
      outline: '2px solid #0396A6',
    },
    marginRight: '10px !important',
    zIndex: '1000 !important',
  },
});

const InvoiceDetail = ({
  invoiceDetails,
  updatedData,
  debitNote,
  isLoading,
  getSingleInvoiceDetails,
  updateInvoiceDetail,
  creditNote,
  generateDebitNote,
  generateCreditNote,
  sendDebitNote,
  sendDebitInvoice,
  sendCreditNote,
  receiptNote,
  generateReceiptNote,
  sendReceiptNote,
  addPayment,
  getAllAccounts,
}) => {
  const { id } = useParams();
  const classes = useStyles();
  const location = useLocation();
  const { handleReqError } = useErrorHandler();
  const [invoiceModalAction, setInvoiceModalAction] = useState(null);
  const [lastRefreshHistory, setLastRefreshHistory] = useState('');
  const [updateUpload, setUpdateUpload] = useState('');
  const [addingPayment, setAddingPayment] = useState(false);

  const fetchInvoiceDetails = async () => {
    const res1 = await getSingleInvoiceDetails(id);

    if (!res1?.success) {
      handleReqError(res1);
    } else if (location?.state?.payment) {
      await viewCreditNote(res1?.data)();
    }
  };

  /**
   * @description - function to get permium remitted history
   */
  const [remitHistory, setRemitHistory] = useState([]);

  const getRemitHistory = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/invoice/remitHistory/${id}`);
      const remitDocument = response.data.data;
      setRemitHistory(remitDocument);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  /**
   * @description - function to get VAT remitted history
   */
  const [allRemittedVat, setAllRemittedVat] = useState([]);

  const getAllRemittedVat = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/invoice/allVatRemitted/${id}`);
      const allVat = response.data.data;
      setAllRemittedVat(allVat);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoiceDetails();
    getRemitHistory();
    getAllRemittedVat();
  }, [id, lastRefreshHistory, updateUpload]);

  const [showModal, setShowModal] = useState(false);

  const [preview, setPreview] = useState(defaultPreviewState);
  const [loading, setLoading] = useState(false);
  const [remittanceId, setRemittanceId] = useState();
  const [receiptPreview, setReceiptPreview] = useState({
    show: false,
    url: '',
    filename: '',
  });
  const [vatRemitId, setVatRemitId] = useState();
  const [vatPreview, setVatPreview] = useState({
    show: false,
    url: '',
    filename: '',
  });

  const closePreview = () => setPreview(defaultPreviewState);

  const toggleModal = () => setShowModal(!showModal);

  const history = useHistory();

  const viewInvoicePayments = (item) => () =>
    history.push(`/invoice/${item.invoice_no}/payments`);

  /**
   * @description - function to generate debit invoice
   * @param {Object} item
   * @returns {Function}
   */
  const previewDocument = (item, url, type, filename) => async () => {
    if (item.InvoiceDocuments.length) {
      setPreview({
        open: true,
        url,
        type,
        filename,
      });
    }
  };

  /**
   * @description - function to generate debit note after payment
   * @param {Object} item
   * @returns {Function}
   */
  const viewDebitNote = (item) => async () => {
    setLoading(true);
    const res = await generateDebitNote(item.invoice_id);

    if (res?.success) {
      previewDocument(
        item,
        res.data.pathToDebitNoteFile.replace('http', 'https'),
        'invoice',
        'debit_invoice.pdf',
      )();
    } else {
      handleReqError(res);
    }

    setLoading(false);
  };

  /**
   * @description - function to generate receipt note after payment
   * @param {Object} item
   * @returns {Function}
   */
  const viewReceiptNote = (item) => async () => {
    setLoading(true);
    const res = await generateReceiptNote(item.invoice_id);

    if (res?.success) {
      previewDocument(
        item,
        res.data.pathToReceiptNoteFile.replace('http', 'https'),
        'receipt',
        'receipt_invoice.pdf',
      )();
    } else {
      handleReqError(res);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (debitNote && location?.state?.payment) {
      setPreview({
        open: true,
        url: debitNote.pathToDebitNoteFile.replace('http', 'https'),
        type: 'debit note',
        filename: 'debit_note.pdf',
      });
    }
  }, [debitNote]);

  useEffect(() => {
    if (creditNote && location?.state?.payment) {
      setPreview({
        open: true,
        url: creditNote.pathToCreditNoteFile.replace('http', 'https'),
        type: 'credit note',
        filename: 'credit_note.pdf',
      });
    }
  }, [creditNote]);

  useEffect(() => {
    if (receiptNote && location?.state?.payment) {
      setPreview({
        open: true,
        url: receiptNote.pathToReceiptNoteFile.replace('http', 'https'),
        type: 'receipt note',
        filename: 'receipt_note.pdf',
      });
    }
  }, [receiptNote]);

  const sendDocument = async (type) => {
    let send;
    if (type === 'debit note') {
      send = sendDebitNote;
    } else if (type === 'credit note') {
      send = sendCreditNote;
    } else if (type === 'receipt') {
      send = sendReceiptNote;
    } else {
      send = sendDebitInvoice;
    }

    const res = await send(invoiceDetails.invoice_id);
    if (res?.success) {
      toast.success(
        type === 'debit note' || type === 'invoice' || type === 'receipt'
          ? 'Document sent to client'
          : 'Document sent to underwriter',
      );
    } else {
      handleReqError(res);
    }
  };

  /**
   * @description - function to generate credit note to be sent to underwriter
   * @param {Object} item
   * @returns {Function}
   */
  const viewCreditNote = (item) => async () => {
    setLoading(true);
    const res = await generateCreditNote(item.invoice_id);

    if (!res?.success) {
      handleReqError(res);
    } else {
      previewDocument(
        item,
        res?.data?.pathToCreditNoteFile?.replace?.('http', 'https'),
        'credit note',
        'credit_note.pdf',
      )();
    }

    setLoading(false);
  };

  /**
   * Determine the remittance status of the current invoice
   * @param {Object} remittedStatus
   * @param {Object} invoiceDetail
   * @returns
   */

  const [remittedStatus, setRemittedStatus] = useState(null);

  const getRemittedStatus = (remittedStatus, invoiceDetail) => {
    return remittedStatus === null
      ? invoiceDetail &&
        invoiceDetail.status &&
        invoiceDetail.status.toLowerCase() === 'paid'
        ? invoiceDetail.remitted === 0
          ? '/Unremitted'
          : `/${invoiceDetail.remitted_status}`
        : ''
      : remittedStatus;
  };

  /**
   * function to get the unremitted premium amount
   * @param {Object} unremittedPremium
   * @param {Object} invoiceDetail
   * @returns
   */
  const [getUnremittedData, setGetUnremittedData] = useState({});

  const getUnremittedAmount = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/invoice/getRemitted/${id}`);
      const remitData = response.data.data;
      setGetUnremittedData(remitData);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  /**
   * Update the status of an Invoice by remitting the premium paid
   * @param {Object} payload
   * @returns
   */
  const handleMarkAsRemitted = async (payload) => {
    setLoading(true);

    try {
      const res = await axios.put(`/invoice/remitted/${id}`, payload);

      const remittanceStatus = `/${res?.data?.data?.[1].remitted_status}` ?? '';
      toast.success(`Premium is successfully remitted!`);
      setRemittedStatus(remittanceStatus);
      await getUnremittedAmount();
      setLastRefreshHistory(new Date().toLocaleString());
      return res;
    } catch (e) {
      toast.error('Could not remit premium!');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUnremittedAmount();
  }, []);

  const handleRemitPremium = () => {
    toggleModal();
    setInvoiceModalAction('remit premium');
  };

  /**
   * function to upload underwriters receipt after remitting a premium on a paid invoice
   * @param {Object} payload
   * @returns
   */

  const uploadRemittanceDocument = async (payload) => {
    setLoading(true);

    try {
      const res = await axios.post(`/invoice/uploadDocument/${id}`, payload);

      toast.success(`Upload was successful!`);
      setUpdateUpload();
      return res;
    } catch (e) {
      toast.error('Could not upload!');
    } finally {
      setLoading(false);
    }
  };

  /**
   * function to get the unremitted VAT amount
   * @param {Object} vat
   * @param {Object} invoiceDetail
   * @returns
   */

  const [getUnremittedVatData, setGetUnremittedVatData] = useState({});

  const getUnremittedVat = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/invoice/getVatToRemit/${id}`);
      const remitData = response.data.data;
      setGetUnremittedVatData(remitData);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  /**
   * Update the status of an Invoice by remitting the VAT paid
   * @param {Object} payload
   * @returns
   */

  const handleVatRemitted = async (payload) => {
    setLoading(true);

    try {
      const res = await axios.put(`/invoice/vatremit/${id}`, payload);

      toast.success(`VAT is successfully remitted!`);
      await getUnremittedVat();
      setLastRefreshHistory(new Date().toLocaleString());
      return res;
    } catch (e) {
      toast.error('Could not remit VAT, it has been fully remitted!');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUnremittedVat();
  }, []);

  const handleRemitVat = () => {
    toggleModal();
    setInvoiceModalAction('remit vat');
  };

  /**
   * function to upload the vat receipt after remitting VAT on a paid invoice
   * @param {Object} payload
   * @returns
   */

  const uploadVatDocument = async (payload) => {
    setLoading(true);

    try {
      const res = await axios.post(`/invoice/uploadvat/${id}`, payload);

      toast.success(`Upload was successful!`);
      setUpdateUpload();
      return res;
    } catch (e) {
      toast.error('Could not upload!');
    } finally {
      setLoading(false);
    }
  };

  const isEndorsementRemittance =
    invoiceDetails?.transaction_type ===
    InvoiceTransactionTypes.POLICY_EDORSEMENT_REMITTANCE;

  const isEndorsement =
    invoiceDetails?.transaction_type ===
    InvoiceTransactionTypes.POLICY_EDORSEMENT;

  const getRemitSend = () => {
    if (!isEndorsementRemittance) {
      return false;
    }

    if (
      preview.type === 'debit note' ||
      preview.type === 'invoice' ||
      preview.type === 'receipt'
    ) {
      return 'Send to underwriter';
    }

    return 'Send to client';
  };

  const getSend = () => {
    if (
      preview.type === 'debit note' ||
      preview.type === 'invoice' ||
      preview.type === 'receipt'
    ) {
      return 'Send to client';
    }

    return 'Send to underwriter';
  };

  const actions = (
    <Button
      onClick={() => sendDocument(preview.type)}
      className={classes.button}
    >
      {getRemitSend() || getSend()}
    </Button>
  );

  const dropDownMenu = () => (
    <div className="w-40">
      {invoiceDetails.status.toLowerCase() !== 'paid' &&
      !isEndorsementRemittance &&
      !isEndorsement ? (
        <button
          onClick={() => {
            toggleModal();
            setInvoiceModalAction('update');
          }}
          className="hover:bg-blue-400 px-3 py-2 text-sm cursor-pointer text-blue-850 hover:text-white hover:font-bold w-full text-left"
        >
          Update Invoice
        </button>
      ) : null}
      {invoiceDetails.status.toLowerCase() === 'payment pending' ||
      invoiceDetails.status.toLowerCase() === 'paid' ? (
        <button
          disabled={
            invoiceDetails.status.toLowerCase() !== 'paid' &&
            invoiceDetails.status.toLowerCase() !== 'payment pending'
          }
          className={`${
            invoiceDetails.status.toLowerCase() !== 'paid' &&
            invoiceDetails.status.toLowerCase() !== 'payment pending'
              ? 'cursor-not-allowed'
              : ''
          } px-3 py-2 hover:bg-blue-400 text-sm cursor-pointer w-full text-blue-850 text-left hover:text-white hover:font-bold`}
          onClick={viewDebitNote(invoiceDetails)}
        >
          View Debit Note
        </button>
      ) : null}
      {invoiceDetails.status.toLowerCase() !== 'paid' ? (
        <button
          className="px-3 py-2 hover:bg-blue-400 text-sm cursor-pointer w-full text-blue-850 text-left hover:text-white hover:font-bold"
          onClick={() => {
            // modify this function
            toggleModal();
            setInvoiceModalAction('add payment');
          }}
        >
          {isEndorsementRemittance ? 'Recieve payment' : 'Add payment'}
        </button>
      ) : null}
      {invoiceDetails.status.toLowerCase() === 'paid' ? (
        <button
          disabled={invoiceDetails.status.toLowerCase() !== 'paid'}
          className={`${
            invoiceDetails.status.toLowerCase() !== 'paid'
              ? 'cursor-not-allowed'
              : ''
          } px-3 py-2 hover:bg-blue-400 w-full text-left text-sm cursor-pointer text-blue-850 hover:text-white hover:font-bold`}
          onClick={viewCreditNote(invoiceDetails)}
        >
          View Credit Note
        </button>
      ) : null}
      {invoiceDetails.status.toLowerCase() === 'paid' ||
      invoiceDetails.status.toLowerCase() === 'partial payment' ? (
        <button
          disabled={
            invoiceDetails.status.toLowerCase() !== 'paid' &&
            invoiceDetails.status.toLowerCase() !== 'partial payment'
          }
          className={`${
            invoiceDetails.status.toLowerCase() !== 'paid'
              ? 'cursor-not-allowed'
              : ''
          } px-3 py-2 hover:bg-blue-400 w-full text-left text-sm cursor-pointer text-blue-850 hover:text-white hover:font-bold`}
          onClick={viewReceiptNote(invoiceDetails)}
        >
          View Receipt
        </button>
      ) : null}
      {invoiceDetails.status.toLowerCase() === 'paid' &&
      !isEndorsementRemittance ? (
        <button
          disabled={invoiceDetails.status.toLowerCase() !== 'paid'}
          className={`${
            invoiceDetails.status.toLowerCase() !== 'paid'
              ? 'cursor-not-allowed'
              : ''
          } px-3 py-2 hover:bg-blue-400 w-full text-left text-sm cursor-pointer text-blue-850 hover:text-white hover:font-bold`}
          onClick={handleRemitPremium}
        >
          Remit Premium
        </button>
      ) : null}
      {invoiceDetails.status.toLowerCase() === 'paid' &&
      !isEndorsementRemittance ? (
        <button
          disabled={invoiceDetails.status.toLowerCase() !== 'paid'}
          className={`${
            invoiceDetails.status.toLowerCase() !== 'paid'
              ? 'cursor-not-allowed'
              : ''
          } px-3 py-2 hover:bg-blue-400 w-full text-left text-sm cursor-pointer text-blue-850 hover:text-white hover:font-bold`}
          onClick={handleRemitVat}
        >
          Remit Vat
        </button>
      ) : null}
      <button
        className={
          'px-3 hover:bg-blue-400 w-full text-left py-2 text-sm cursor-pointer text-blue-850 hover:text-white hover:font-bold'
        }
        onClick={viewInvoicePayments(invoiceDetails)}
      >
        View Transactions
      </button>
      {!isEndorsementRemittance ? (
        <button
          className={`${
            invoiceDetails.status === 'PAYMENT PENDING' ||
            invoiceDetails.quote_id
              ? 'hidden'
              : ''
          } px-3 hover:bg-blue-400 w-full text-left py-2 text-sm cursor-pointer text-blue-850 hover:text-white hover:font-bold`}
          onClick={() =>
            history.push(
              `/account/invoices/${invoiceDetails.invoice_id}/createPolicy`,
            )
          }
        >
          Convert to Policy
        </button>
      ) : null}
    </div>
  );

  const formatRate = (type, rate) => {
    return type === 'Flat' ? formatMoney(rate) : rate;
  };

  const getType = (updatedData, invoiceDetails) => {
    return (
      (updatedData && updatedData.commission_type) ||
      (invoiceDetails && invoiceDetails.commission_type)
    );
  };

  const getRate = (updatedData, invoiceDetails) => {
    return (
      (updatedData && updatedData.commission_rate) ||
      (invoiceDetails && invoiceDetails.commission_rate)
    );
  };

  const getVATRemitStatus = (vat_paid, vat_remitted) => {
    if (vat_remitted === vat_paid) {
      return 'Fully Remitted';
    } else if (vat_remitted > 0 && vat_remitted !== 0) {
      return 'Partial Remittance';
    } else {
      return 'Unremitted';
    }
  };

  return isLoading ? (
    <PageLoader />
  ) : invoiceDetails ? (
    <>
      {loading ? <PageLoader /> : null}
      <div className="px-4 mt-10 xl:mt-24 lg:mt-24 md:mt-24 xl:px-14 lg:px-14 md:px-14 pb-14">
        <Back text="go back" />
        <div className="flex items-center justify-between mb-2">
          <h1 className="text-xl font-bold">Invoice Details</h1>
          <p>
            Invoice Status:{' '}
            <span
              className={`${
                invoiceDetails.status.includes('pending')
                  ? 'text-blue-850'
                  : invoiceDetails.status.includes('partial')
                  ? 'text-orange-800'
                  : 'text-green-850'
              } uppercase font-bold`}
            >
              {updatedData
                ? `${updatedData.status}${getRemittedStatus(
                    remittedStatus,
                    updatedData,
                  )}`
                : `${invoiceDetails.status}${getRemittedStatus(
                    remittedStatus,
                    invoiceDetails,
                  )}`}
            </span>
          </p>
        </div>

        {invoiceDetails.status === 'PAID' && (
          <div className="flex items-center justify-end mb-2">
            <p>
              VAT Status:{' '}
              <span
                className={`${
                  invoiceDetails.vat_paid === invoiceDetails.vat_remitted
                    ? 'text-green-850'
                    : invoiceDetails.vat_remitted < invoiceDetails.vat_paid &&
                      invoiceDetails.vat_remitted > 0
                    ? 'text-orange-800'
                    : 'text-green-850'
                } uppercase font-bold`}
              >
                {getVATRemitStatus(
                  invoiceDetails.vat_paid,
                  invoiceDetails.vat_remitted,
                )}
              </span>
            </p>
          </div>
        )}

        <div className="w-full pb-6 mt-6 shadow-pc">
          <div className="relative flex items-center justify-between h-12 px-8 bg-brown-100">
            <div className="flex items-center w-48">
              <h1>{invoiceDetails.invoice_no}</h1>
            </div>
            {dropDownMenu && (
              <>
                {
                  <OptionsDropDown
                    dropdownContent={dropDownMenu()}
                    dropDownIcon={
                      <div className="flex items-center cursor-pointer">
                        <span className="font-extrabold text-blue-800">
                          Action
                        </span>
                        <img src={caretIcon} className="ml-1" alt="" />
                      </div>
                    }
                  />
                }
              </>
            )}
          </div>

          <div className="px-3 pt-8 tracking-widest xl:px-10 lg:px-10 md:px-10 lg:flex lg:justify-between md:block lg:mb-1 md:mb-0">
            <div className="block px-3 py-2 mb-2 lg:w-8/25 md:w-full bg-blue-50">
              <h6 className="block text-gray-600 uppercase text-tiny">
                {invoiceDetails.Client && invoiceDetails.Client.firstname
                  ? 'Client Name'
                  : 'Company Name'}
              </h6>
              <p className="block mt-2 text-base text-gray-700">
                {invoiceDetails.Client && invoiceDetails.Client.firstname
                  ? `${invoiceDetails.Client.firstname} ${invoiceDetails.Client.lastname}`
                  : invoiceDetails.CorporateClient.company_name}
              </p>
            </div>
            <div className="block px-3 py-2 mb-2 lg:w-8/25 md:w-full bg-blue-50">
              <h6 className="block text-gray-600 uppercase text-tiny">
                Insurer
              </h6>
              <p className="block mt-2 text-base text-gray-700 uppercase">
                {invoiceDetails.InsuranceProduct.InsuranceProvider.name}
              </p>
            </div>
            <div className="block px-3 py-2 mb-2 lg:w-8/25 md:w-full bg-blue-50">
              <h6 className="block text-gray-600 uppercase text-tiny">
                Invoice Issue Date
              </h6>
              <p className="block mt-2 text-base text-gray-700">
                {invoiceDetails.invoice_issue_date &&
                  formatDate(invoiceDetails.invoice_issue_date)}
              </p>
            </div>
          </div>
          <div className="px-3 tracking-widest xl:px-10 lg:px-10 md:px-10 lg:flex lg:justify-between md:block lg:mb-1 md:mb-0"></div>
          <div className="px-3 xl:px-10 lg:px-10 md:px-10 lg:flex lg:justify-between md:block lg:mb-1 md:mb-0">
            <div className="block px-3 py-2 mb-2 lg:w-8/25 md:w-full bg-blue-50">
              <h6 className="block text-gray-600 uppercase text-tiny">
                Amount due
              </h6>
              <p className="block mt-2 text-base text-gray-700">
                {updatedData
                  ? formatMoney(updatedData.amount)
                  : formatMoney(invoiceDetails.amount) || 'N/A'}
              </p>
            </div>
            <div className="block px-3 py-2 mb-2 lg:w-8/25 md:w-full bg-blue-50">
              <h6 className="block text-gray-600 uppercase text-tiny">
                Amount Paid
              </h6>
              <p className="block mt-2 text-base text-gray-700">
                {formatMoney(invoiceDetails.amount_paid) || 'N/A'}
              </p>
            </div>
            <div className="block px-3 py-2 mb-2 lg:w-8/25 md:w-full bg-blue-50">
              <h6 className="block text-gray-600 uppercase text-tiny">
                Product
              </h6>
              <p className="block mt-2 text-base text-gray-700">
                {invoiceDetails.InsuranceProduct.product_name}
              </p>
            </div>
          </div>

          <div className="px-3 xl:px-10 lg:px-10 md:px-10 lg:flex lg:justify-between md:block lg:mb-1 md:mb-0">
            <div className="block px-3 py-2 mb-2 lg:w-8/25 md:w-full bg-blue-50">
              <h6 className="block text-gray-600 uppercase text-tiny">
                Address
              </h6>
              <p className="block mt-2 text-base text-gray-700">
                {invoiceDetails.Client && invoiceDetails.Client.ClientAddress
                  ? `${
                      invoiceDetails.Client.ClientAddress.address
                    }, ${capitalize(invoiceDetails.Client.ClientAddress.state)}`
                  : `${
                      invoiceDetails.CorporateClient.CorporateClientAddress
                        .operating_business_addre
                    }, ${capitalize(
                      invoiceDetails.CorporateClient.CorporateClientAddress
                        .city_of_operation,
                    )} ${
                      invoiceDetails.CorporateClient.CorporateClientAddress
                        .state_of_operation
                    }`}
              </p>
            </div>
            <div className="block px-3 py-2 mb-2 lg:w-8/25 md:w-full bg-blue-50">
              <h6 className="block text-gray-600 uppercase text-tiny">
                Quote No.
              </h6>
              <p className="block mt-2 text-base text-gray-700">
                {invoiceDetails.Quote
                  ? invoiceDetails.Quote.quote_number
                  : 'N/A'}
              </p>
            </div>
            <div className="block px-3 py-2 mb-2 lg:w-8/25 md:w-full bg-blue-50">
              <h6 className="block text-gray-600 uppercase text-tiny">
                Period
              </h6>
              <p className="block mt-2 text-base text-gray-700">
                {updatedData
                  ? `${formatDate(updatedData.start_date)} to ${formatDate(
                      updatedData.end_date,
                    )}`
                  : `${formatDate(invoiceDetails.start_date)} to ${formatDate(
                      invoiceDetails.end_date,
                    )}`}
              </p>
            </div>
          </div>
          <div className="px-3 xl:px-10 lg:px-10 md:px-10 lg:flex lg:justify-between md:block lg:mb-1 md:mb-0">
            <div className="block px-3 py-2 mb-2 lg:w-8/25 md:w-full bg-blue-50">
              <h6 className="block text-gray-600 uppercase text-tiny">
                Commission Type
              </h6>
              <p className="block mt-2 text-base text-gray-700">
                {getType(updatedData, invoiceDetails) || 'N/A'}
              </p>
            </div>
            <div className="block px-3 py-2 mb-2 lg:w-8/25 md:w-full bg-blue-50">
              <h6 className="block text-gray-600 uppercase text-tiny">
                Commission Rate
              </h6>
              <p className="block mt-2 text-base text-gray-700">
                {formatRate(
                  getType(updatedData, invoiceDetails),
                  getRate(updatedData, invoiceDetails),
                ) || 'N/A'}
              </p>
            </div>
            <div className="block px-3 py-2 mb-2 lg:w-8/25 md:w-full bg-blue-50">
              <h6 className="block text-gray-600 uppercase text-tiny">
                Commission
              </h6>
              <p className="block mt-2 text-base text-gray-700">
                {(updatedData && formatMoney(updatedData.commission)) ||
                  (invoiceDetails && formatMoney(invoiceDetails.commission))}
              </p>
            </div>
          </div>
          <div className="px-3 xl:px-10 lg:px-10 md:px-10 lg:flex lg:justify-between md:block lg:mb-1 md:mb-0">
            <div className="block px-3 py-2 mb-2 lg:w-8/25 md:w-full bg-blue-50">
              <h6 className="block text-gray-600 uppercase text-tiny">
                Insured
              </h6>
              <p className="block mt-2 text-base text-gray-700">
                {invoiceDetails.insured ||
                  invoiceDetails.Quote?.name_of_insured}
              </p>
            </div>
            <div className="block px-3 py-2 mb-2 lg:w-8/25 md:w-full bg-blue-50">
              <h6 className="block text-gray-600 uppercase text-tiny">
                Sum Insured
              </h6>
              <p className="block mt-2 text-base text-gray-700">
                {(updatedData?.sum_insured &&
                  formatMoney(updatedData.sum_insured)) ||
                  (invoiceDetails?.sum_insured &&
                    formatMoney(invoiceDetails.sum_insured))}
              </p>
            </div>
            <div className="hidden lg:block px-3 py-2 mb-2 lg:w-8/25 md:w-full"></div>
          </div>
          <div className="px-3 xl:px-10 lg:px-10 md:px-10 lg:flex lg:justify-between md:block lg:mb-1 md:mb-0">
            <div className="block w-full px-3 py-2 mb-2 bg-blue-50">
              <h6 className="block text-gray-600 uppercase text-tiny">
                Narration
              </h6>
              <p className="block mt-2 text-base text-gray-700">
                {updatedData ? updatedData.narration : invoiceDetails.narration}
              </p>
            </div>
          </div>
        </div>

        {invoiceDetails.CoBrokers && invoiceDetails.CoBrokers.length > 0 && (
          <div className="w-full mt-6 rounded-t shadow-pc">
            <div className="flex items-center h-12 px-8 rounded-t bg-brown-100">
              <h1>Co-Broker(s)</h1>
            </div>
            <table className="w-full border border-collapse">
              <tbody>
                <tr className="px-10 text-left text-white bg-blue-850">
                  <th className="p-3 border">S/N</th>
                  <th className="p-3 border">Co-Broker Name</th>
                  <th className="p-3 border">Percentage of Co-Broker</th>
                </tr>
                {invoiceDetails.CoBrokers.map((data, index) => (
                  <tr key={index} className="bg-gray-100">
                    <td className="p-3 border">{index + 1}</td>
                    <td className="p-3 border">
                      {data && data?.name_of_co_broker}
                    </td>
                    <td className="p-3 border">
                      {data && data?.InvoiceCoBroker?.percentage_of_premium}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {invoiceDetails.CoInsurers && invoiceDetails.CoInsurers.length > 0 && (
          <div className="w-full mt-6 rounded-t shadow-pc">
            <div className="flex items-center h-12 px-8 rounded-t bg-brown-100">
              <h1>Co-Insurer(s)</h1>
            </div>
            <table className="w-full border border-collapse">
              <tbody>
                <tr className="px-10 text-left text-white bg-blue-850">
                  <th className="p-3 border">S/N</th>
                  <th className="p-3 border">Co-Insurer Name</th>
                  <th className="p-3 border">Percentage of Co-Insurer</th>
                </tr>
                {invoiceDetails.CoInsurers.map((data, index) => (
                  <tr key={index} className="bg-gray-100">
                    <td className="p-3 border">{index + 1}</td>
                    <td className="p-3 border">
                      {data && data?.name_of_co_insurer}
                    </td>
                    <td className="p-3 border">
                      {data && data?.InvoiceCoInsurer?.percentage_of_premium}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {invoiceDetails.status.toLowerCase() === 'paid' && (
          <div>
            <section className="w-full pb-6 mt-6 rounded-t shadow-pc">
              <div className="relative flex items-center justify-between h-12 px-8 bg-brown-100">
                <div className="flex items-center w-48">
                  <h1>Premium Remittance</h1>
                </div>
              </div>

              {remitHistory.length > 0 && (
                <div className="w-full mt-6 rounded-t shadow-pc">
                  {receiptPreview?.show && (
                    <PreviewModal
                      url={receiptPreview?.url}
                      fileName={receiptPreview?.filename}
                      download={(fileKey) => {
                        download(fileKey, receiptPreview?.filename);
                      }}
                      closePreview={() => {
                        setReceiptPreview({
                          show: false,
                          url: '',
                          filename: '',
                        });
                      }}
                      actions={''}
                    />
                  )}
                  <table className="w-full border border-collapse">
                    <tbody>
                      <tr className="px-10 text-left text-white bg-blue-850">
                        <th className="p-3 border">S/N</th>
                        <th className="p-3 border">Remittance Mode</th>
                        <th className="p-3 border">Bank Name</th>
                        <th className="p-3 border">Cheque No</th>
                        <th className="p-3 border">Receipt No</th>
                        <th className="p-3 border">&nbsp;</th>
                      </tr>

                      {remitHistory.map((data, index) => {
                        return (
                          <tr key={index} className="bg-gray-100">
                            <td className="p-3 border">{index + 1}</td>
                            <td className="p-3 border">
                              {data && data?.remittance_mode}
                            </td>
                            <td className="p-3 border">
                              {data && data?.remittance_bank_name}
                            </td>
                            <td className="p-3 border">
                              {data && data?.remittance_cheque_number}
                            </td>
                            <td className="p-3 border">
                              {data && data?.ModeOfRemittanceDocuments[0] && (
                                <span
                                  style={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                  }}
                                  title="Click here to view Uploaded Receipt"
                                  onClick={() => {
                                    setReceiptPreview({
                                      show: true,
                                      url:
                                        data?.ModeOfRemittanceDocuments[0]
                                          ?.underwriter_receipt_url,
                                      filename:
                                        data?.ModeOfRemittanceDocuments[0]
                                          ?.document_name,
                                    });
                                  }}
                                >
                                  {
                                    data.ModeOfRemittanceDocuments[0]
                                      .underwriter_receipt_number
                                  }
                                </span>
                              )}
                            </td>
                            <td className="p-3 border">
                              <button
                                onClick={() => {
                                  toggleModal();
                                  setRemittanceId(data.remittance_log_id);
                                  setInvoiceModalAction('upload');
                                }}
                                className="bg-blue-750 p-2 font-bold text-white uppercase rounded-sm text-tiny"
                              >
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  color="white"
                                  className="mr-2"
                                />
                                <span>upload receipt</span>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </section>

            <section className="w-full pb-6 mt-6 rounded-t shadow-pc">
              <div className="relative flex items-center justify-between h-12 px-8 bg-brown-100">
                <div className="flex items-center w-48">
                  <h1>VAT Remittance</h1>
                </div>
              </div>

              {allRemittedVat.length > 0 && (
                <div className="w-full mt-6 rounded-t shadow-pc">
                  {vatPreview?.show && (
                    <PreviewModal
                      url={vatPreview?.url}
                      fileName={vatPreview?.filename}
                      download={(fileKey) => {
                        download(fileKey, vatPreview?.filename);
                      }}
                      closePreview={() => {
                        setVatPreview({
                          show: false,
                          url: '',
                          filename: '',
                        });
                      }}
                      actions={''}
                    />
                  )}
                  <table className="w-full border border-collapse">
                    <tbody>
                      <tr className="px-10 text-left text-white bg-blue-850">
                        <th className="p-3 border">S/N</th>
                        <th className="p-3 border">Remittance Mode</th>
                        <th className="p-3 border">Bank Name</th>
                        <th className="p-3 border">Cheque No</th>
                        <th className="p-3 border">Receipt No</th>
                        <th className="p-3 border">&nbsp;</th>
                      </tr>

                      {allRemittedVat.map((data, index) => {
                        return (
                          <tr key={index} className="bg-gray-100">
                            <td className="p-3 border">{index + 1}</td>
                            <td className="p-3 border">
                              {data && data?.vat_remittance_mode}
                            </td>
                            <td className="p-3 border">
                              {data && data?.vat_bank_name}
                            </td>
                            <td className="p-3 border">
                              {data && data?.vat_cheque_number}
                            </td>
                            <td className="p-3 border">
                              {data && data?.VatRemittanceDocuments[0] && (
                                <span
                                  style={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                  }}
                                  title="Click here to view Uploaded Receipt"
                                  onClick={() => {
                                    setVatPreview({
                                      show: true,
                                      url:
                                        data?.VatRemittanceDocuments[0]
                                          ?.vat_receipt_url,
                                      filename:
                                        data?.VatRemittanceDocuments[0]
                                          ?.document_name,
                                    });
                                  }}
                                >
                                  {
                                    data.VatRemittanceDocuments[0]
                                      .vat_receipt_number
                                  }
                                </span>
                              )}
                            </td>
                            <td className="p-3 border">
                              <button
                                onClick={() => {
                                  toggleModal();
                                  setVatRemitId(data.vat_log_id);
                                  setInvoiceModalAction('upload vat');
                                }}
                                className="bg-blue-750 p-2 font-bold text-white uppercase rounded-sm text-tiny"
                              >
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  color="white"
                                  className="mr-2"
                                />
                                <span>upload vat here</span>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </section>
          </div>
        )}

        <InvoiceDetailModal
          showModal={showModal}
          invoiceId={id}
          addingPayment={addingPayment}
          setAddingPayment={setAddingPayment}
          invoiceModalAction={invoiceModalAction}
          closeModal={toggleModal}
          isInvoiceLoading={loading}
          setLoading={setLoading}
          status={invoiceDetails.status}
          startDate={invoiceDetails.start_date}
          commissionRate={invoiceDetails.commission_rate}
          commissionType={invoiceDetails.commission_type}
          updatedData={updatedData}
          modalType="update"
          endDate={invoiceDetails.end_date}
          invoiceDetails={{ invoiceDetails }}
          amountInsured={formatMoney(invoiceDetails.amount)}
          narration={invoiceDetails.narration}
          updateInvoiceDetail={updateInvoiceDetail}
          handleMarkAsRemitted={handleMarkAsRemitted}
          remittanceId={remittanceId}
          uploadRemittanceDocument={uploadRemittanceDocument}
          amountUnremitted={invoiceDetails.amount_unremitted}
          getUnremittedData={getUnremittedData}
          closePreview={closePreview}
          generateReceiptNote={generateReceiptNote}
          getUnremittedVatData={getUnremittedVatData}
          handleVatRemitted={handleVatRemitted}
          uploadVatDocument={uploadVatDocument}
          vatRemitId={vatRemitId}
          addPaymentToAnInvoiceAction={addPayment}
          searchAccounts={getAllAccounts}
        />
      </div>
      {preview.open ? (
        <PreviewModal
          url={preview.url}
          fileName={preview.filename}
          download={(fileKey) => download(fileKey, preview.filename)}
          closePreview={closePreview}
          actions={actions}
        />
      ) : null}
    </>
  ) : null;
};
const mapStateToProps = (state) => {
  return {
    invoiceDetails: state.invoices.invoiceData,
    isLoading: state.invoices.isLoading,
    updatedData: state.invoices.invoiceUpdateData,
    creditNote: state.quotes.creditNoteData,
    debitNote: state.quotes.debitNoteData,
    receiptNote: state.quotes.receiptNoteData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSingleInvoiceDetails: (invoiceId) =>
    dispatch(getSingleInvoiceAction(invoiceId)),
  updateInvoiceDetail: (payload, invoiceId) =>
    dispatch(updateInvoiceDetailsAction(payload, invoiceId)),
  generateNotes: (invoiceId) => dispatch(generateDebitNoteAction(invoiceId)),
  sendDebitNote: (invoiceId) =>
    dispatch(sendDebitNoteToClientAction(invoiceId)),
  generateCreditNote: (invoiceId) =>
    dispatch(generateCreditNoteAction(invoiceId)),
  generateDebitNote: (invoiceId) =>
    dispatch(generateDebitNoteAction(invoiceId)),
  sendDebitInvoice: (invoiceId) => dispatch(sendInvoiceAction(invoiceId)),
  sendCreditNote: (invoiceId) => dispatch(sendCreditNoteAction(invoiceId)),
  generateReceiptNote: (invoiceId) =>
    dispatch(generateReceiptNoteAction(invoiceId)),
  sendReceiptNote: (invoiceId) =>
    dispatch(sendReceiptNoteToClientAction(invoiceId)),
  addPayment: (invoiceNo, payload) =>
    dispatch(addPaymentToAnInvoiceAction(invoiceNo, payload)),
  getAllAccounts: (accountName) => dispatch(getAllAccounts(accountName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail);
